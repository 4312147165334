/*!

 =========================================================
 * Material Dashboard React - v1.10.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/*DS: significantly trimmed down on 2nd dec 2022*/

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  letter-spacing: normal;
}
*:focus {
  outline: 0;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
}
@media (max-width: 600px) {
  body, html {
    overflow-x: hidden;
  }
}
@media (max-width: 991px) {
  body, html {
    position: relative;
  }
}
body, h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}
h1 {
  font-size: 3em;
  line-height: 1.15em;
}
h2 {
  font-size: 2.4em;
}
h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}
h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}
h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}
h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}
blockquote p {
  font-style: italic;
}
a {
  color: #9c27b0;
  text-decoration: none;
}
a:hover, a:focus {
  color: #89229b;
}

